<template>
  <div>
    <div class="section-1">
      <div class="pl-grid">
        <div class="header">
          <img class="logo hidden md:block" src="../assets/images/logo.png">
          <img class="welcome-banner" src="../assets/images/welkom.png">
        </div>
        <div class="title">
          <!-- <template v-if="videoHash">
            <span class="text-capitalize">{{ salutation || '' }}</span> {{ insertion || ''}} {{ lastName || ''}}<br>
          </template>
          Bekijk hier uw persoonlijke video -->
          Bekijk hier uw video
        </div>
        <div class="flex justify-center">
          <div class="video-container" ref="video" :style="{ minHeight: `${videoHeight}px`}">
            <template v-if="!loading && error">
                Uw video kon niet worden geladen
            </template>
            <div v-show="loading" class="flex items-center">
                <svg class="animate-spin -ml-1 mr-6 h-16 w-16" style="color: #FF9800;" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                  <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                Uw video wordt geladen
            </div>
            <div v-show="!loading" id="player" />
            <img class="absolute hidden md:block --top-left" src="../assets/images/1-conf-top-left.png">
            <img class="absolute hidden md:block --top-right" src="../assets/images/1-conf-top-right.png">
            <img class="absolute hidden md:block --bottom-right" src="../assets/images/1-conf-bottom-right.png">
          </div>
        </div>
      </div>
    </div>
    <div class="section-2">
      <img class="section-banner" src="../assets/images/section-2-banner.png">
      <div class="h-full w-full overflow-hidden py-32">
        <div class="pl-grid flex justify-center">
          <div class="section-2-content">
            <div class="nicolette">
              <div class="w-full h-full relative">
                <img class="glow" src="../assets/images/glow.png">
                <img class="absolute --left" src="../assets/images/2-conf-left.png">
                <img class="absolute --right" src="../assets/images/2-conf-right.png">
              </div>
              <img class="nicolette-img" src="../assets/images/nicolette.png">
            </div>
          <!-- <img class="section-2-banner" src="../assets/images/section-2-banner.png"> -->
            <div class="steps-portlet">
              <div class="steps-title">
                De voordelen van een persoonlijke pagina
              </div>
              <div class="step --1">
                <div class="number">1</div>
                Een overzicht van uw loten en gewonnen prijzen
              </div>
              <div class="step --2">
                <div class="number">2</div>
                Profiteer van leuke kortingen, winacties en voordelen
              </div>
              <div class="step --3">
                <div class="number">3</div>
                Makkelijk en snel uw gegevens wijzigen
              </div>
              <a href="https://www.postcodeloterij.nl/persoonlijke-pagina/uw-account-aanmaken" target="_blank" rel="noreferrer noopener" class="btn-section-2">
                Uw pagina aanmaken
                <fa icon="angle-right" class="fa-icon" />
              </a>
              <img class="absolute --bottom-right" src="../assets/images/2-conf-bottom-right.png">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section-3">
      <div class="pl-grid">
        <div class="prizes">
          <img class="prizes-img --desktop hidden md:block" src="../assets/images/prizes.png">
          <img class="prizes-img --mobile md:hidden" src="../assets/images/prizes--mobile.png">
        </div>
      </div>
    </div>
    <div class="section-4">
      <img class="section-banner" src="../assets/images/section-4-banner.png">
      <div class="pl-grid flex justify-center">
        <div class="faq w-full">
          <FAQ />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FAQ from './FAQ.vue';

export default {
  name: 'Main',
  components: {
    FAQ,
  },
  data() {
    return {
      salutation: '',
      insertion: '',
      lastName: '',
      videoHash: '',
      videoPlayer: null,
      loading: true,
      error: false,
      videoHeight: 50,
    };
  },
  mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    const hash = urlParams.get('video');
    if (hash) this.videoHash = hash;

    // Set default height for the empty video div to have less of a page shift when it loads
    const width = this.$refs.video.offsetWidth;
    this.videoHeight = width * 0.574;

    window.setVariables = () => {
      const gender = this.videoPlayer.getParameterValueByName('geslacht');
      this.salutation = gender === 'F' ? 'Mevr.' : 'Dhr.';
      const insertion = `${this.videoPlayer.getParameterValueByName('tussenvoegsel')}`;
      this.insertion = insertion && insertion !== 'null' ? insertion : '';
      const lastName = `${this.videoPlayer.getParameterValueByName('achternaam')},`;
      this.lastName = lastName && lastName !== 'null' ? lastName : '';
      this.loading = false;
    };

    if (this.videoHash) {
      try {
        // eslint-disable-next-line no-undef
        this.videoPlayer = new StoryteqVideoPlayer({
          videoPlayerId: 'player',
          videoHash: this.videoHash,
          dataCallbackFunction: 'setVariables',
          autoplay: false,
        });
      } catch (err) {
        this.loading = false;
        this.error = true;
      }
    } else {
      this.loading = false;
      this.error = true;
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus">

.section-1
  display flex
  flex-direction column
  align-items center
  background radial-gradient(at 50% 60%, #FF221C, #BD0024)
  padding-bottom 8.5rem
  overflow hidden

.title
  color white
  text-align center
  font-weight 700
  font-size 2.6rem
  line-height 1.3
  margin-top 1.5rem
  margin-bottom 2.5rem

.header
  display flex
  align-items center
  position relative
  height 100px
  padding-top 10px

.welcome-banner
  position absolute
  top 50%
  left 50%
  transform translate(-50%,-50%)
  height 90px

.logo
  position absolute
  top 50%
  left 0
  transform translateY(-50%)
  height 55px

.video-container
  position relative
  display flex
  justify-content center
  align-items center
  width 100%
  max-width 660px
  border 10px solid white
  background-image linear-gradient(rgba(0, 0, 0, 0.5),rgba(0, 0, 0, 0.5)), url('../assets/images/thumbnail.png')
  background-size cover
  color white
  font-weight 700
  font-size 1.6rem
  .--top-left
    top -55px
    left -50px
  .--top-right
    top -70px
    right -90px
  .--bottom-right
    bottom -45px
    right -80px
  @media (min-width 768px)
    font-size 2rem

.section-2
  background-color #60B6ED
  position relative

.section-banner
  position absolute
  top -30px
  left 50%
  transform translateX(-50%)
  max-height 60px
  max-width 90%
  height auto

.section-2-content
  position relative
  display flex
  align-items center
  justify-content flex-end
  width 100%
  max-width 660px

.nicolette
  position absolute
  top -3rem
  left 50%
  transform translateX(-50%)

  .nicolette-img
    position relative
    height 60vh
    max-width unset

  .--left
    top 20vw
    left -20vw

  .--right
    top 5vh
    right -15vw

  @media (min-width: 768px)
    transform none
    left 3%

    .nicolette-img
      position relative
      height calc(100% + 220px)

    .--left
      top 120px
      left -50px
    .--right
      top 0
      right -10%

.glow
  position absolute
  top -5vh
  left 50%
  transform translateX(-53%)
  width 230vw
  max-width none

  @media (min-width: 768px)
    top 0
    transform translateX(-52%)
    width 100vw
    max-width 1500px

.steps-portlet
  position relative
  display flex
  flex-direction column
  justify-content center
  background white
  border 5px solid #707070
  padding 25px 20px
  width 100%
  margin-top 35vh

  .--bottom-right
    top -15vh
    left 0

  @media (min-width: 768px)
    width 50%
    margin-top 0

    .--bottom-right
      top unset
      left unset
      bottom -35px
      right -50px

.steps-title
  color #BD0024
  font-size 2.4rem
  font-weight 700
  line-height 1.2
  text-align center
  margin-bottom 1rem

.step
  display flex
  align-items flex-start
  width 100%
  font-weight 400
  color #555
  font-size 1.8rem
  padding 10px 0

  &.--1,
  &.--2
    border-bottom 1px solid #EDE7DC

  .number
    min-height 25px
    min-width 25px
    margin-right 15px
    margin-top 5px
    text-align center
    border-radius 50%
    color white
    font-size 1.6rem
    line-height 25px
    font-weight 900
    background-color #FF9800

.btn-section-2
  position relative
  color #fff
  background-color #009b00
  background: linear-gradient(90deg,#009b00,#76bc31)
  border-bottom 1px solid #00610c
  border-radius 6px
  border-top 0
  border-left 0
  border-right 0
  text-align center
  width 100%
  font-weight 700
  text-transform uppercase
  line-height 50px
  font-size 2rem
  cursor pointer
  margin-top 10px

  &:hover
    background linear-gradient(90deg,#007300,#3b9819)

  .fa-icon
    position absolute
    right 8px
    top 50%
    transform translateY(-48%)
    font-size 2rem

    @media (max-width: 330px)
      display none

.section-3
  padding-bottom 50px
  background-color #F0EDE7

  @media (min-width 768px)
    padding 50px 0 70px 0

.prizes
  position relative
  display flex
  flex-direction column
  justify-content flex-end
  align-items center
  min-height calc(90vw * 3.60)

  .prizes-img
    max-width 850px

  @media (min-width 768px)
    min-height unset

  .prizes-img.--mobile
    position absolute
    left 50%
    top -9vw
    transform translateX(-50%)
    width 90vw

.fine-print
  font-size 1.2rem
  color #707070
  text-align center
  max-width 810px
  margin-top 1rem

.section-4
  position relative
  padding 75px 0 40px

.faq
  display flex
  justify-content center
  max-width 620px
  width 100%

</style>
