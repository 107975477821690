import Vue from 'vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import App from './App.vue';
import './styles/tailwind.css';

Vue.config.productionTip = false;
library.add(fas, faFacebook, faInstagram);
Vue.component('fa', FontAwesomeIcon);

new Vue({
  render: (h) => h(App),
}).$mount('#app');
