<template>
  <div class="w-full">
    <collapse :is-open="active.FAQ1" class="collapse w-full mb-8">
      <template slot="header">
        <div @click="active.FAQ1 = !active.FAQ1" :class="['collapse-header', active.FAQ1 && '--active']">
          Wanneer zijn de trekkingen van de Nationale Postcode Loterij?
          <fa icon="angle-down" />
        </div>
      </template>
      <template slot="content">
        <div class="collapse-content">
          <p>
            De maandelijkse
            <a
              href="https://www.postcodeloterij.nl/hoe-werkt-het/trekkingsdata"
              title="trekking"
              target="_blank"
              rel="noopener noreferrer">trekking</a>
            van de Nationale Postcode Loterij vindt plaats op de laatste dag van de maand.
            De trekkingen vinden plaats vanaf 10.00 uur in de Beethovenstraat 200 te Amsterdam.
          </p>
          <p>
            De
            <a
              href="https://www.postcodeloterij.nl/uitslagen"
              title="uitslagen"
              target="_blank"
              rel="noopener noreferrer">uitslagen</a>
            van de maandelijkse trekkingen worden elke 1e van de volgende maand gepubliceerd. Wijzigingen voorbehouden.
          </p>
          <p>
            Wilt u weten of u 14 keer per jaar kans maakt op de miljoenen prijzen van de Nationale Postcode Loterij én u maximale Kanjerpunten spaart?
            Kijk op uw
            <a
              href="https://www.postcodeloterij.nl/persoonlijke-pagina/mijn-postcodeloterij"
              title="Persoonlijke Pagina"
              target="_blank"
              rel="noopener noreferrer">Persoonlijke Pagina</a>.
          </p>
        </div>
      </template>
    </collapse>
    <collapse :is-open="active.FAQ2" class="collapse mb-8">
      <template slot="header">
        <div @click="active.FAQ2 = !active.FAQ2" :class="['collapse-header', active.FAQ2 && '--active']">
          Wat zijn Kanjerpunten?
          <fa icon="angle-down" />
        </div>
      </template>
      <template slot="content">
        <div class="collapse-content">
          <p>
            Kanjerpunten zijn spaarpunten.
            U kunt uw Kanjerpunten inzien op uw
            <a
              href="https://www.postcodeloterij.nl/persoonlijke-pagina/mijn-postcodeloterij"
              target="_blank"
              rel="noopener noreferrer">persoonlijke pagina</a>.
          </p>
          <p>
            U spaart tien Kanjerpunten per lot, per trekking. U kunt per kalenderjaar per lot <b>maximaal 140 Kanjerpunten</b> sparen.
            Er zijn 14 trekkingen per jaar: twaalf maandelijkse trekkingen en twee extra trekkingen.
            Elk jaar in januari begint u opnieuw met het sparen van Kanjerpunten.
          </p>
          <p>
            Hoe <b>langer</b> een lot meespeelt in de maanden voorafgaand aan de trekking,
            hoe <b>meer</b> Kanjerpunten en hoe groter het aandeel in de prijs.
          </p>
          <p>
            <b>Speelt u met meerdere loten mee?</b>
            Dan worden al uw Kanjerpunten bij elkaar opgeteld.
          </p>
        </div>
      </template>
    </collapse>
    <collapse :is-open="active.FAQ3" class="collapse mb-8">
      <template slot="header">
        <div @click="active.FAQ3 = !active.FAQ3" :class="['collapse-header', active.FAQ3 && '--active']">
          Wilt u meer informatie over de goede doelen?
          <fa icon="angle-down" />
        </div>
      </template>
      <template slot="content">
        <div class="collapse-content">
          <p>
            De Nationale Postcode Loterij steunt goede doelen op het gebied van mens en natuur.&nbsp;
          </p>
          <p>Informatie over onze goede doelen vindt u
            <a href="https://www.postcodeloterij.nl/goede-doelen/overzicht" target="_blank" rel="noopener noreferrer">hier</a>.
          </p>
        </div>
      </template>
    </collapse>
    <collapse :is-open="active.FAQ4" class="collapse mb-8">
      <template slot="header">
        <div @click="active.FAQ4 = !active.FAQ4" :class="['collapse-header', active.FAQ4 && '--active']">
          Hoe wijzig ik mijn gegevens?
          <fa icon="angle-down" />
        </div>
      </template>
      <template slot="content">
        <div class="collapse-content">
          <p>
            Wijzig eenvoudig uw naam, adres, e-mailadres en/of telefoonnummer op uw
            <a
              href="https://www.postcodeloterij.nl/persoonlijke-pagina/mijn-postcodeloterij"
              target="_blank"
              rel="noopener noreferrer">persoonlijke pagina</a>.
          </p>
          <p>
            <b>Wilt u uw adres wijzigen?<br></b>Als u uw adres wijzigt via uw persoonlijke pagina, dan gaat u automatisch meespelen met uw nieuwe postcode.&nbsp;
          </p>
          <p>Wilt u op uw oude postcode blijven meespelen? Dat kan! Neem <a href="https://faq.postcodeloterij.nl/" target="_blank" rel="noopener noreferrer">contact</a> op met onze Ledenservice. In het filmpje hieronder vindt u alle belangrijke informatie over het wijzigen van uw adres.</p>
          <div class="flex justify-center">
            <iframe
            width=" 480"
            height="270"
            src="https://www.youtube.com/embed/JU7VWmXh_94?feature=oembed"
            frameborder="0"
            allowfullscreen="allowfullscreen" />
          </div>
        </div>
      </template>
    </collapse>
  </div>
</template>
<script>
import collapse from './collapse.vue';

export default {
  components: {
    collapse,
  },
  data() {
    return {
      active: {
        FAQ1: false,
        FAQ2: false,
        FAQ3: false,
        FAQ4: false,
      },
    };
  },
  methods: {
    toggleCollapse1() {
      // eslint-disable-next-line no-unneeded-ternary
      this.active.FAQ1 = !this.active.FAQ1 ? true : false;
    },
  },
};
</script>

<style lang="stylus" scoped>

.collapse-header
  display flex
  justify-content space-between
  align-items center
  padding 10px 25px
  font-size 1.6rem
  font-weight 400
  color #555
  background-color #F9F7F2
  border 1px solid #D7D4CD
  border-radius 6px
  white-space normal
  min-height 50px

  &:hover
    cursor pointer

  svg
    transition transform 300ms ease
    margin-left 10px

  &.--active
    svg
      transform rotate(180deg)

.collapse-content
  padding 15px 25px 0 25px

p
  font-size 1.7rem
  line-height 1.8
  font-weight 400
  color #3b3b3b
  margin-bottom 1rem

a
  color #e30027
  &:hover
    text-decoration underline

</style>
