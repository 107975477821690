<template>
  <div>
    <div
      @click="$emit('clicked')"
      :class="[
        'collapse__header',
        !transitioning && open ? 'collapse-open' : 'collapse-closed',
        transitioning && 'collapse-transitioning',
      ]">
      <slot name="header" />
    </div>

    <div
      :class="[
        'collapse__content',
        !transitioning && open ? 'collapse-open' : 'collapse-closed',
        transitioning && 'collapse-transitioning',
      ]"
      :style="{
        height,
        transitionProperty: 'height',
        transitionDuration,
        transitionTimingFunction,
        transitionDelay,
      }"
      @transitionend="handleEnd">
      <div class="collapse__contentInner" ref="inner">
        <slot name="content" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Collapsible',
  data() {
    return {
      open: this.isOpen,
      height: this.isOpen ? 'auto' : '0px',
      closeOnNextTick: false,
      transitioning: false,
    };
  },
  props: {
    openLabel: {
      type: String,
      default: 'Close me',
    },
    closedLabel: {
      type: String,
      default: 'Open me',
    },
    transitionDuration: {
      type: String,
      default: '400ms',
    },
    transitionTimingFunction: {
      type: String,
      default: 'ease',
    },
    transitionDelay: {
      type: String,
      default: '0s',
    },
    isOpen: {
      default: true,
      type: Boolean,
    },
    onCollapse: {
      default: () => {},
      type: Function,
    },
  },
  methods: {
    handleClick() {
      this.onCollapse(!this.isOpen);
      this.open = !this.open;
    },
    handleEnd() {
      if (this.height !== '0px') {
        this.height = 'auto';
      }
      this.transitioning = false;
    },
  },
  watch: {
    open(newVal, oldVal) {
      this.transitioning = true;
      this.height = `${this.$refs.inner.scrollHeight}px`;

      if (oldVal === true) {
        this.closeOnNextTick = true;
      }
    },
    isOpen(newVal) {
      this.open = newVal;
    },
  },
  updated() {
    this.$nextTick(() => {
      window.setTimeout(() => {
        if (this.closeOnNextTick) {
          this.height = '0px';
          this.closeOnNextTick = false;
        }
      });
    });
  },
};
</script>

<style scoped>
.collapse__content {
  overflow: hidden;
}

.collapse__contentInner {
  height: auto;
}

.collapse__header {
  appearance: none;
  border: 0;
  background: transparent;
  border-radius: 0;
  font-family: inherit;
  font-size: inherit;
  font-style: inherit;
  text-align: inherit;
  color: inherit;
  padding: 0;
  margin: 0;
  display: block;
  width: 100%;
  cursor: pointer;
}
</style>
