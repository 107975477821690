var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{class:[
      'collapse__header',
      !_vm.transitioning && _vm.open ? 'collapse-open' : 'collapse-closed',
      _vm.transitioning && 'collapse-transitioning' ],on:{"click":function($event){return _vm.$emit('clicked')}}},[_vm._t("header")],2),_c('div',{class:[
      'collapse__content',
      !_vm.transitioning && _vm.open ? 'collapse-open' : 'collapse-closed',
      _vm.transitioning && 'collapse-transitioning' ],style:({
      height: _vm.height,
      transitionProperty: 'height',
      transitionDuration: _vm.transitionDuration,
      transitionTimingFunction: _vm.transitionTimingFunction,
      transitionDelay: _vm.transitionDelay,
    }),on:{"transitionend":_vm.handleEnd}},[_c('div',{ref:"inner",staticClass:"collapse__contentInner"},[_vm._t("content")],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }